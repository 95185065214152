export const nkHeadline = (sidebar: boolean) =>
  !sidebar ? '' : '!pl-[var(--enki-default-gutter)] !pr-[var(--enki-default-gutter)]';

export const nkModuleWrap = (sidebar: boolean) =>
  !sidebar ? '' : '!pl-[var(--enki-default-gutter)] !pr-[var(--enki-default-gutter)]';

export const nkFreeHtml = (sidebar: boolean) =>
  !sidebar ? '' : '!pl-0 !pr-0 !ml-[var(--enki-default-gutter)] !mr-[var(--enki-default-gutter)] overflow-x-hidden';

export const nkPDPMerch = (sidebar: boolean) =>
  !sidebar ? '' : '!pl-[var(--enki-default-gutter)] !pr-[var(--enki-default-gutter)]';

export const nkFooterLeft = (sidebar: boolean) => '';

export const nkFooterRight = (sidebar: boolean) => '';

export const nkCMSPoolSkeletonWrap = (sidebar: boolean) =>
  !sidebar ? '' : '!w-full !pl-[var(--enki-default-gutter)] !pr-[var(--enki-default-gutter)]';

export const nkCMSPoolSkeletonSKUCarouselWrap = (sidebar: boolean) =>
  !sidebar
    ? ''
    : '!grid !gap-x-[var(--enki-sku-gutter,16px)] grid-cols-3 enki-lg:grid-cols-4 md:grid-cols-5 enki-xl:grid-cols-5 enki-2xl:grid-cols-6 enki-3xl:grid-cols-7';

export const nkCMSPoolSkeletonSKUCarouselItem = (sidebar: boolean) => (!sidebar ? '' : '!w-full !mr-0');

export const nkCMSPoolSectionWrap = (sidebar: boolean) => (!sidebar ? '' : '!w-full !max-w-full');

export const nkCMSPoolSectionTitleWrap = (sidebar: boolean) =>
  !sidebar ? '' : '!pl-[var(--enki-default-gutter)] !pr-[var(--enki-default-gutter)]';

export const nkHeadlineViewMoreIcon = (sidebar: boolean) => (!sidebar ? '' : 'w-[27px]');

export const nkNoticeFullWidthBanner = (sidebar: boolean) =>
  !sidebar ? '' : '!pt-[0px] !pl-[40px] !pr-[40px] !pb-[0px]';

export const nkBannerItem = (sidebar: boolean) => (!sidebar ? '' : '!w-full !mx-0');
