import classNames from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type IconProps = {
  className?: string;
  type: string;
  colorful?: boolean;
  style?: React.CSSProperties;
  [key: string]: any;
};

export default function Icon(props: IconProps) {
  const { type, className, colorful = false, ...rest } = props;
  return colorful ? (
    <svg {...rest} className={classNames(styles.color, className)}>
      <use xlinkHref={`#icon-color-${type}`} />
    </svg>
  ) : (
    <i {...rest} className={classNames(`font_family icon-${type}`, styles.icon, className)} />
  );
}
