import Icon from '@/components/common/Icon';
import { TopRelatedProps } from '@/types/product';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { FC, memo } from 'react';

import { nkModuleWrap } from '@/constants/sidebar-subtle-tweaks/global';
import { twMerge } from 'tailwind-merge';

type IProps = {
  data?: TopRelatedProps;
  isEnkiABTestGroup?: boolean;
  className?: string;
  contentWrapCls?: string;
  title?: string;
};

const TopRelatedModule: FC<IProps> = props => {
  const { data, isEnkiABTestGroup = false, className, contentWrapCls, title } = props;
  const { t } = useTranslation('common');

  if (!data?.length) return null;
  return (
    <div className={twMerge('my-[60px]', className, nkModuleWrap(isEnkiABTestGroup))}>
      <div className="enki-body-xl !text-[20px] text-black">{title || t('top_related')}</div>
      <div className={twMerge('mt-6 flex items-center flex-wrap', contentWrapCls)}>
        {data?.map((item, index) => (
          <Link key={`${index}-${item.query}`} href={item.url} prefetch={false}>
            <a target="_blank" className="mt-4" aria-label={`${item?.label}_${index}`}>
              <div className="enki-body-sm-medium inline-flex items-center mr-4 border border-solid px-3 py-2 border-[#EAEAEA] rounded-full text-[#111] bg-white cursor-pointer hover:bg-[#EAEAEA]">
                <span title={item.label} className="line-clamp-1 flex-1">
                  {item.label}
                </span>
                <Icon style={{ fontSize: 24 }} className="ml-2 flex-none" type="icon-arrow-right-s1" />
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(TopRelatedModule);
